#accordion {
  .card-header {
    border-radius: 5px 5px 0px 0px;
    background-color: lightgrey;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;

    button {
      margin: auto;
      align-items: center;
      background-color: transparent;
    }

    .card {
      .collapse {
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }
}