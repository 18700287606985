.blog {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #dee2e6;

  &__image {
    width: 35%;
  }

  &__content {
    margin: 1rem;

    @include limit-lines($min_width, $max_width, (22px/1.5), 22px, 1.8/1.5, 1.8, 2);
    font-weight: 400;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      text-transform: none;
    }
  }
}

a.blog {
  position: relative;
  margin-bottom: 2rem;
  padding-right: 3.5rem;
  color: $body-color;
  text-decoration: none;
  @include transition(0.3s);

  &:before {
    display: inline-block;
    margin-left: 1rem;
    width: 30px;
    height: 30px;
    min-width: 30px;
    border: 3px solid;
    border-radius: 15px;
    @extend .gelrekoffie-icons;
    @extend .gelrekoffie-icons-arrow-next;
    font-size: 9px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    order: 2;
    transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
  }

  &:hover{
    background-color: $grey;
    &:before {
      transform: translateX(0.5rem);
    }
  }
}

.blog-list {
  .blog {
    &__image {
      width: 35%;
    }
    &__content {
      width: 65%;
    }
  }
}

@include media-breakpoint-up(sm) {
  .blog {
    &__image {
      width: 25%;
    }
  }
}

@include media-breakpoint-up(md) {
  .blog {
    &__image {
      width: 60%;
    }
  }

  .blog-list {
    .blog {
      &__image {
        width: 50%;
      }
      &__content {
        width: 50%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog {
    &__image {
      width: 40%;
    }

    &__content {
      margin: 0 1rem;
      width: 60%;
    }
  }
}

@include media-breakpoint-up(xl) {
  a.blog {
    &:before {
      margin-left: 1.5rem;
      width: 44px;
      height: 44px;
      min-width: 44px;
      border: 4px solid;
      border-radius: 22px;
      font-size: 11px;
      line-height: 36px;
    }
  }
  .blog {
    &__image {
      width: 25%;
    }

    &__content {
      margin: 0 0 0 2rem;
    }
  }

  .blog-list {
    .blog {
      &__image {
        width: 40%;
      }
      &__content {
        width: 60%;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .blog {
    &__image {
      width: 16%;
    }
  }
  .blog-list {
    .blog {
      &__image {
        width: 35%;
      }
      &__content {
        width: 65%;
      }
    }
  }
}