.image-header {
  position: relative;
  z-index: 0;

  &-overlay-new {
    background-color: rgba(29, 38, 43, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display:grid;
    grid-template-columns: 1fr 12fr 1fr;
    grid-template-areas:
            "left-gutter content right-gutter";

    display: -ms-grid;
    -ms-grid-columns: 1fr 12fr 1fr;
  }
  .image-header__content {
    grid-column: content;
    -ms-grid-column: 2;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__background {
    position: relative;
    min-height: 160px;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+20,000000+100&0.2+40,0+100 */
      background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    margin-bottom: 80px;

    color: $white;
  }

  &__title {
    @include fluid-type($min_width, $max_width, (38px/1.5), 38px, 1, 1);
    color: $white;
    text-transform: uppercase;
  }

  &__subtitle {
    display: flex;
    align-items: center;

    @extend %abuget;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    color: $white;
    font-weight: normal;

    &--arrow {
      &:before {
        display: inline-block;
        margin-left: -64px;
        width: 44px;
        height: 44px;
        min-width: 44px;
        border: 4px solid;
        border-radius: 22px;
        @extend .gelrekoffie-icons;
        @extend .gelrekoffie-icons-arrow-next;
        font-size: 11px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        order: 2;
        transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
        pointer-events: none;
      }

      &:hover {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    a {
      padding-right: 80px;
      color: inherit;
      text-decoration: none;
    }
  }

  //specific styling for homepage
  &--home {
    .image-header__background {
      min-height: 300px;
    }

    .image-header__content {
      position: relative;
      top: auto;
      transform: none;
    }

    .image-header__left {
      padding-top: 4rem;
    }

    .image-header__middle {
      display: none;
    }

    .image-header__right {
      padding-bottom: 4rem;
    }

    .image-header__title {
      margin-bottom: 1rem;
    }

    .image-header__subtitle {
      margin-bottom: 1rem;
      @include fluid-type($min_width, $max_width, (118px/1.8), 118px, 1, 1);
    }
  }
  //specific styling for filterpage
  &--filters {
    .image-header__background {
      min-height: 340px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .image-header {
    &--home {
      .image-header__content {
        display:grid;
        grid-template-columns: 2fr 1fr;

        display: -ms-grid;
        -ms-grid-columns: 2fr 1fr;

        > div {

          &:nth-of-type(1) {
            -ms-grid-column: 1;
          }

          &:nth-of-type(2) {
            -ms-grid-column: 2;
          }

          &:nth-of-type(3) {
            -ms-grid-column: 1;
            -ms-grid-row: 2;
          }
        }
      }

      .image-header__middle {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 80%;
        margin: 0 auto;

        figure {
          margin-bottom: -8rem;
        }
      }

      .image-header__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {
    &--home {
      .image-header__content {
        grid-template-columns: 3fr 3fr 3fr;
        -ms-grid-columns: 3fr 3fr 3fr;

        > div {
          min-height: 300px;

          &:nth-of-type(3) {
            -ms-grid-column: 3;
            -ms-grid-row: 1;
          }
        }
      }

      .image-header__middle {
        figure {
          margin-bottom: -5rem;
        }
      }

      .image-header__right {
        align-items: flex-end;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-header {
    &--home {

      .image-header__background {
        min-height: 380px;
      }

      .image-header__content {
        grid-template-columns: 4fr 3fr 4fr;
        -ms-grid-columns: 4fr 3fr 4fr;
      }

      .image-header__left {
        padding-top: 6rem;
      }

      .image-header__right {
        padding-bottom: 6rem;
      }
    }

    //specific styling for filterpage
    &--filters {
      .image-header__background {
        min-height: 280px;
      }
    }

    &-overlay {
      grid-template-columns: 2fr 12fr 2fr;
      -ms-grid-columns: 2fr 12fr 2fr;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .image-header {
    &--home {

      .image-header__content {
        grid-template-columns: 5fr 3fr 5fr;
        -ms-grid-columns: 5fr 3fr 5fr;
      }
    }
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  //z-index: -2;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}