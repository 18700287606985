.follow-us {
  padding: 1rem 0;
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .share__title {
    position: relative;
    padding: $spacer 0;

    @extend %abuget;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    font-weight: normal;
    text-transform: none;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline;
      padding: 0;
      text-align: center;

      &:not(:last-child) {
        margin-right: 2rem;
      }

      a {
        color: $body-color;

        i {
          background-color: transparent;
          display: inline-block;
          margin-right: 4px;

          width: 32px;
          height: 32px;
          @include fluid-type($min_width, $max_width, (23px/1.3), 23px, 1, 1);
          text-align: center;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .share {
    flex-direction: row;
    ul {
      margin: 0 0 0 2.5rem;
    }
  }
}
