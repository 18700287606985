.product-categories-component {
  position: relative;
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter product-categories-component__title right-gutter"
          "left-gutter product-categories-component__items right-gutter"
          "left-gutter product-categories-component__footer right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &:before {
    content: "";
    background-color: $grey;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__title {
    margin-bottom: 2rem;
    grid-area: product-categories-component__title;
    -ms-grid-column-span: 3;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0;
    }
  }

  &__items {
    grid-area: product-categories-component__items;
    -ms-grid-column: 2;
    -ms-grid-row: 2;

    margin-bottom: 4rem;
  }

  &__footer {
    grid-area: product-categories-component__footer;
    -ms-grid-column: 2;
    -ms-grid-row: 3;

    margin-bottom: 2rem;
    text-align: center;
  }
}

.product-category {
  background-color: $white;
  margin-bottom: 8rem;

  a {
    text-decoration: none;
  }

  &__title {
    position: relative;
    margin: 0 1.25rem;
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    &:before {
      display: inline-block;
      margin-left: 1rem;
      width: 30px;
      height: 30px;
      min-width: 30px;
      border: 3px solid;
      border-radius: 15px;
      @extend .gelrekoffie-icons;
      @extend .gelrekoffie-icons-arrow-next;
      font-size: 9px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      order: 2;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    }
  }

  &__image {
    position: relative;
    margin: 0 1.25rem;

    &-background {
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      .bg-progressive {
        > div {
          backface-visibility: hidden;
          transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
        }
      }
    }

    &-product {
      width: 60%;
      margin: 0 auto;
      transform: translateY(6rem);
      figure {
        transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      }
    }
  }

  &__content {
    @include fluid-type($min_width, $max_width, (21px/1.2), 21px, (1.6/1.2), 1.6);
    color: $body-color;
  }

  a:hover {
    .product-category {
      &__title {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    .product-category__image-background {
      .bg-progressive {
        .background-image-large {
          opacity: 0.6;
        }
      }
    }
    .product-category__image-product {
      figure {
        transform: scale(1.02);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .product-categories-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;

    &:before {
      height: 50%;
    }

    &__items {
      display:grid;
      grid-template-columns: 4fr 4fr 4fr;
      grid-column-gap: 1rem;

      display: -ms-grid;
      -ms-grid-columns: 4fr 1rem 4fr 1rem 4fr;
    }
  }

  .product-category {
    margin-bottom: 4rem;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
    }
  }
}

@include media-breakpoint-up(lg) {
  .product-categories-component {
    &__items {
      grid-column-gap: 2rem;
      -ms-grid-columns: 4fr 2rem 4fr 2rem 4fr;
    }
  }

  .product-category {
    margin-bottom: 5rem;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
    }
  }
}

@include media-breakpoint-up(xl) {
  .product-category {
    &__title {
      margin: 0 3rem;

      &:before {
        margin-left: 1.5rem;
        width: 44px;
        height: 44px;
        min-width: 44px;
        border: 4px solid;
        border-radius: 22px;
        font-size: 11px;
        line-height: 36px;
      }
    }
  }
}

.product-filters {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-end;
  height: 100%;

  &__title {
    margin-bottom: 2rem;
    @include fluid-type($min_width, $max_width, (34px/1.5), 34px, 1.2, 1.2);
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
  }

  h3 {
    margin-bottom: 0.5rem;
    color: $white;
  }
}