body {
  @extend %gill-sans;
  @include fluid-type($min_width, $max_width, (20px/1.2), 20px, (1.4/1.2), 1.4);
  color: $body-color;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  //display: flex;
  //align-items: center;
  margin-top: 0;
  margin-bottom: 0.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.75rem;
  &.lead {
    text-transform: uppercase;
  }
}

h1,.h1 {
  margin-bottom: 2.5rem;
  @extend %gill-sans;
  @include fluid-type($min_width, $max_width, (26px/1.6), 26px, (1), 1);
  font-weight: normal;
  strong {
    margin: 0 0.5rem 0 0;
    //@extend %abuget;
    //@include fluid-type($min_width, $max_width, (90px/1.6), 90px, (1/1.6), 1);
    //font-weight: normal;
    //text-transform: none;
    //vertical-align: middle;
  }
}

h2,.h2 {
  @extend %gill-sans;
  @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1.2, 1.2);
  font-weight: 700;
  text-transform: uppercase;
  strong, b {
    font-weight: 700;
  }
}

h3,.h3 {
  @extend %gill-sans;
  @include fluid-type($min_width, $max_width, (18px/1.5), 18px, 1, 1);
  font-weight: 700;
  text-transform: uppercase;
  strong, b {
    font-weight: 700;
  }
}

h4,.h4, h5,.h5,h6,.h6 {
  @include fluid-type($min_width, $max_width, (16px/1.5), 16px, 1, 1);
  font-weight: 700;
  text-transform: uppercase;
  strong, b {
    font-weight: 700;
  }
}