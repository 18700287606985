.subnav-wrapper {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter subnav right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  border-bottom: 1px solid;
  ul.subnav {
    padding: 0;
    margin: 0.5rem 0;
    grid-column: subnav;
    -ms-grid-column: 2;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      flex-wrap: wrap;
      padding-right: 1rem;
      a {
        @include fluid-type($min_width, $max_width, (16px/1.25), 16px, (1.6/1.25), 1.6);
        color: inherit;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .subnav-wrapper {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;

    ul.subnav {
      li {
        padding-right: 2rem;
      }
    }
  }
}