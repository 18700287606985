footer {
  padding: 2rem 0;
}

.footer-block {
  margin-bottom: 2rem;
  text-align: center;

  @include fluid-type($min_width, $max_width, (18px/1.25), 18px, (1.6/1.25), 1.6);
  color: $grey-dark;

  a {
    color: $grey-dark;
    text-decoration: none;
    &.highlighted {
      color: $primary;
    }
  }

  .navbar-brand {
    img {
      max-width: 58px;
    }
  }
}

.footer-logo {
  text-align: center;

  &__image {
    font-size: 0;
    line-height: inherit;
    img {
      max-width: 58px;
    }
  }

  &__content {
    @include fluid-type($min_width, $max_width, (16px/1.5), 16px, (1.6/1.5), 1.6);
    font-weight: bold;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(sm) {
  .footer-wrapper {
    display:grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 2rem;

    display: -ms-grid;
    -ms-grid-columns: 2fr 2rem 2fr;
  }
}

@include media-breakpoint-up(md) {
  footer {
    grid-template-columns: 2fr 12fr 2fr;
    padding: 4rem 0;
  }

  .footer-wrapper {
      grid-template-columns: 2fr 2fr 2fr 3fr;
      grid-column-gap: 4rem;

      display: -ms-grid;
      -ms-grid-columns: 2fr 4rem 2fr 4rem 2fr 4rem 3fr;
  }

  .footer-block {
    margin-bottom: 0;
    text-align: inherit;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 7;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }
  }
}