.cta {
  position: relative;
  display: block;
  margin-bottom: 1rem;

  &__text {
    position: relative;
    background-color: $grey-dark;
    padding: 1rem 4rem 1rem 1rem;
    @include fluid-type($min_width, $max_width, (22px/1.5), 22px, (1.5/1.25), 1.5);
    text-transform: uppercase;
    color: $white;

    &:before {
      content: "";
      position: absolute;
      right: 1.8rem;
      top: calc(50% - 3px);
      display: block;
      width: 6px;
      height: 6px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-135deg);
    }

    &:after {
      content: "";
      position: absolute;
      right: 1rem;
      top: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border: 3px solid $white;
      border-radius: 15px;
    }
  }

  &__image {
    .image-large {
      transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
    }
  }
}

a.cta {
  text-decoration: none;
  &:hover {
    .cta__image {
      .image-large {
        transform: scale(1.05);
      }
    }
  }
}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(xl) {
  .cta {
    &__text {
      padding: 2rem 5rem 2rem 3rem;
      &:before {
        right: 3.7rem;
        top: calc(50% - 4px);
        width: 8px;
        height: 8px;
        border-left: 3px solid;
        border-bottom: 3px solid;
      }

      &:after {
        right: 2.5rem;
        top: calc(50% - 22px);
        width: 44px;
        height: 44px;
        border: 4px solid $white;
        border-radius: 22px;
      }
    }

  }
}