.breadcrumb-wrapper {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter breadcrumb right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
}
.breadcrumb {
  grid-column: breadcrumb;
  -ms-grid-column: 2;

  li {
    @extend .breadcrumb-item;

    a {
      @include fluid-type($min_width, $max_width, (18px/1.5), 18px, (1.5/1.25), 1.5);
      color: inherit;
      text-decoration: none;
    }

    &.last {
      @extend .active;
    }
  }
}

@include media-breakpoint-up(md) {
  .breadcrumb-wrapper {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
  }
}
