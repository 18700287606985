@font-face {
  font-family: 'Abuget';
  src: url('../fonts/Abuget.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Abuget.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Abuget.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/Abuget.ttf')  format('truetype'), /* Safari, Android, iOS */
}

//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";

%gill-sans {
  font-family: Gill Sans MT, Gill Sans, Calibri, sans-serif;
  //font-family: gill-sans-nova,sans-serif;
  font-weight: 400;
}

%abuget {
  font-family: 'Abuget', sans-serif;
}

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

$border-radius-sm: 0;
$border-radius-lg: 0;