.searchbox-wrapper {
    grid-column: searchbox;
    -ms-grid-column: 3;
    display: flex;
    align-items: center;
    padding: 0 0 0 2rem;
    width: 100%;
    z-index: 2;

    .wrapper {
        position: relative;
        background-color: #f3f1ef;
        padding: 0;
        height: 30px;
        width: 180px;
        border-radius: 15px;
        box-shadow: inset 0 0 6px 3px rgba(18,19,18,0.05);
        cursor: pointer;
        overflow: hidden;

        @extend .gelrekoffie-icons;
        @extend .gelrekoffie-icons-search;

        &:before {
            font-size: 16px;
            color: $primary;
            line-height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
        }
    }

    @include transition(right .3s ease);

    &.searchbox-wrapper--show {
        i {
            cursor: pointer;
        }
    }

    &.searchbox-wrapper--show-button {
        form {
            .form-submit {
                right: 0;
            }
            .form-item {
                input[type="text"] {
                    padding-right: 60px;
                }
            }
        }
    }

    form {
        margin-bottom: 0;
        .form-item {
            position: absolute;
            top: 0;
            right: 20px;
            width: 82%;
            display: inline-block;
            &:before {
                position: absolute;
                left: 20px;
                top: 20px;
                font-size: 1.7125rem;
                line-height: 30px;
                vertical-align: middle;
                pointer-events: none;
            }
        }

        .form-submit {
            position: absolute;
            top: 0;
            right: -80px;
            transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
        }

        input[type="submit"] {
            display: inline-block;
            padding: 0;
            margin: 0;
            width: 70px;
            font-size: 14px;
            line-height: 30px;
            text-transform: uppercase;
        }

        input[type="text"] {
            background-color: transparent;
            padding: 0 0;
            @extend %gill-sans;
            font-size: 17px;
            color: #766e68;
            line-height: 30px;
            height: 30px;
            border: none;
            outline: none;
            text-align: right;
            transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);

            &:focus::-webkit-input-placeholder { color:transparent; }
            &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
            &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
            &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
        }
    }
}
@include media-breakpoint-down(lg) {
    .searchbox-wrapper {
        padding: 10px;
        //position: absolute;
        //top:0;
        //left: 0;
    }
}

@include media-breakpoint-up(sm) {
    .searchbox-wrapper {
        width: auto;
        margin-top: 0;
        //margin-left: auto;

        .wrapper {
            width: 180px;
        }

        &.searchbox-wrapper--show-button {
            form {
                .form-item {
                    input[type="text"] {
                        padding-right: 70px;
                    }
                }
            }
        }

        form {
            input[type="submit"] {
                width: 80px;
            }
            .form-item {
                width: 73%;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .searchbox-wrapper {
        -ms-grid-column: 4;
    }
}

@include media-breakpoint-up(xxl) {
    .searchbox-wrapper {
        .wrapper {
            width: 210px;
        }
        form {
            .form-item {
                width: 75%;
            }
        }
    }
}
