ul.specs {
  margin: 0 0 1rem 0;
  padding: 0.7rem 0.7rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  border: 2px solid rgba($primary,0.3);
  border-radius: 1.75rem;

  @include fluid-type($min_width, $max_width, (12px), 18px, 1, 1);

  li {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    i {
      display: inline-flex;
      margin-right: 0.5rem;
      @include fluid-type($min_width, $max_width, (22px), 26px, 1, 1);
      color: $primary;
      vertical-align: middle;
    }
  }
}

@include media-breakpoint-up(md) {
  ul.specs {
    margin: 0 0 2rem 0;
    li {
      flex-direction: row;
      a {
        flex-direction: row;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  ul.specs {
    margin: 0 0 1rem 0;
    padding: 1rem 3rem 1rem 2rem;
  }
}