//shoppingcart
.table.cart-list {
  margin-top: 2rem;

  .product__image{
    display: block;
    max-width: 80px;
    width: 80px;
    border: 0;
  }

  tr {
    td, th {
      vertical-align: middle;
      text-align: right;
      &:nth-child(1), &:nth-child(2), &:nth-child(6) {
        @include media-breakpoint-down(md) {
          //display: none;
        }
      }

      .remove {
        background-color: $red;
        padding: 2px 14px;
        margin: 0;
        width: 40px;
        height: 40px;
        line-height: 36px;

        //@include media-breakpoint-down(md) {
        //  width: 100%;
        //}
      }

      .input-wrapper {
        max-width: 80px;
        text-align: left;
        @include media-breakpoint-up(sm) {
          margin-left: auto!important;
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      padding-top: 20px;
    }
  }

  tfoot {
    //display: block;
    tr {
      th,td {
        //border: 0;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    tfoot {
      display: block;
      tr {
        border-top: 1px solid #dee2e6;
        th,td {
          //border: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {

  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {

  }

  #no-more-tables tbody th,#no-more-tables tbody td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align:left;
  }

  #no-more-tables tfoot th,#no-more-tables tfoot td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
    padding-top: 0;
    padding-bottom: 0;
    white-space: normal;
    text-align:left;
  }

  #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
  }

  /*
  Label the data
  */
  #no-more-tables td:before { content: attr(data-title); }
}