aside {
  .sidebar-item {
    margin-bottom: 3.5rem;

    .__has-link {
      a {
        color: $red !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}