.products-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter products-component__title right-gutter"
          "left-gutter products-component__items right-gutter"
          "left-gutter products-component__footer right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 0 0 2rem 0;

  &__title {
    padding-bottom: 2rem;
    @extend %abuget;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    font-weight: normal;
    grid-area: products-component__title;
    -ms-grid-column-span: 3;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__items {
    grid-area: products-component__items;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  &__footer {
    grid-area: products-component__footer;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    text-align: center;
  }
}

.product {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  a {
    color: $body-color;
    text-decoration: none;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  &__image {
    position: relative;
    width: 50%;
    margin: 0 auto;

    .image-large {
      transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
    }

    figure {
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      opacity: 1;
    }

    &:before {
      position: absolute;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border: 3px solid;
      border-radius: 15px;
      @extend .gelrekoffie-icons;
      @extend .gelrekoffie-icons-arrow-next;
      font-size: 9px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      z-index: 9;
      opacity: 0;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      pointer-events: none;
    }
  }

  &__content {
    margin-bottom: 1.75rem;
    @include limit-lines($min_width, $max_width, (22px/1.5), 22px, (1.6/1.3), 1.6, 3);
    color: $body-color;
    text-align: center;
    transition: .7s cubic-bezier(.49,1,.22,1) 0ms;

    p {
      @include limit-lines($min_width, $max_width, (18px/1.5), 18px, (1.6/1.3), 1.6, 3);
      color: rgba($body-color,0.6);
      overflow: hidden;
    }
  }

  &__title {
    margin-top: 1.5rem;
    @include fluid-type($min_width, $max_width, (18px/1.5), 18px, (1.6/1.5), 1.6);
    font-weight: bold;
    text-align: center;
    transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    .product__image {

      .image-large {
        transform: scale(1.05);
      }

      figure {
        opacity: 0.25;
      }

      &:before {
        opacity: 1;
      }
    }
    .product__title, .product__content {
      backface-visibility: hidden;
      //transform: translateY(-0.25rem);
    }
  }

  &--cart {
    a {
      display: flex;
    }

    .product__image {
      position: relative;
      width: 20%;
    }
  }
}

@include media-breakpoint-up(md) {
  .products-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 0 0 4rem 0;

    &__title {
      h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
        flex-direction: row;
      }
    }

    &__items {
      display:grid;
      grid-template-columns: 6fr 6fr;

      display: -ms-grid;
      -ms-grid-columns: 6fr 4rem 6fr;
    }
  }

  .product {
    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-of-type(6) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-of-type(7) {
      -ms-grid-column: 1;
      -ms-grid-row: 4;
    }

    &:nth-of-type(8) {
      -ms-grid-column: 3;
      -ms-grid-row: 4;
    }

    &:nth-of-type(9) {
      -ms-grid-column: 1;
      -ms-grid-row: 5;
    }

    &:nth-of-type(10) {
      -ms-grid-column: 3;
      -ms-grid-row: 5;
    }

    &:nth-of-type(11) {
      -ms-grid-column: 1;
      -ms-grid-row: 6;
    }

    &:nth-of-type(12) {
      -ms-grid-column: 3;
      -ms-grid-row: 6;
    }

    &:nth-of-type(13) {
      -ms-grid-column: 1;
      -ms-grid-row: 7;
    }

    &:nth-of-type(14) {
      -ms-grid-column: 3;
      -ms-grid-row: 7;
    }

    &:nth-of-type(15) {
      -ms-grid-column: 1;
      -ms-grid-row: 8;
    }

    &:nth-of-type(16) {
      -ms-grid-column: 3;
      -ms-grid-row: 8;
    }

    &:nth-of-type(17) {
      -ms-grid-column: 1;
      -ms-grid-row: 9;
    }

    &:nth-of-type(18) {
      -ms-grid-column: 3;
      -ms-grid-row: 9;
    }

    &:nth-of-type(19) {
      -ms-grid-column: 1;
      -ms-grid-row: 10;
    }

    &:nth-of-type(20) {
      -ms-grid-column: 3;
      -ms-grid-row: 10;
    }

    &:nth-of-type(21) {
      -ms-grid-column: 1;
      -ms-grid-row: 11;
    }

    &:nth-of-type(22) {
      -ms-grid-column: 3;
      -ms-grid-row: 11;
    }

    &:nth-of-type(23) {
      -ms-grid-column: 1;
      -ms-grid-row: 12;
    }

    &:nth-of-type(24) {
      -ms-grid-column: 3;
      -ms-grid-row: 12;
    }

    &:nth-of-type(25) {
      -ms-grid-column: 1;
      -ms-grid-row: 13;
    }

    &:nth-of-type(26) {
      -ms-grid-column: 3;
      -ms-grid-row: 13;
    }

    &:nth-of-type(27) {
      -ms-grid-column: 1;
      -ms-grid-row: 14;
    }

    &:nth-of-type(28) {
      -ms-grid-column: 3;
      -ms-grid-row: 14;
    }

    &:nth-of-type(29) {
      -ms-grid-column: 1;
      -ms-grid-row: 15;
    }

    &:nth-of-type(30) {
      -ms-grid-column: 3;
      -ms-grid-row: 15;
    }

    &:nth-of-type(31) {
      -ms-grid-column: 1;
      -ms-grid-row: 16;
    }

    &:nth-of-type(32) {
      -ms-grid-column: 3;
      -ms-grid-row: 16;
    }

    &:nth-of-type(33) {
      -ms-grid-column: 1;
      -ms-grid-row: 17;
    }

    &:nth-of-type(34) {
      -ms-grid-column: 3;
      -ms-grid-row: 17;
    }

    &:nth-of-type(35) {
      -ms-grid-column: 1;
      -ms-grid-row: 18;
    }

    &:nth-of-type(36) {
      -ms-grid-column: 3;
      -ms-grid-row: 18;
    }

    &:nth-of-type(37) {
      -ms-grid-column: 1;
      -ms-grid-row: 19;
    }

    &:nth-of-type(38) {
      -ms-grid-column: 3;
      -ms-grid-row: 19;
    }

    &:nth-of-type(39) {
      -ms-grid-column: 1;
      -ms-grid-row: 20;
    }

    &:nth-of-type(40) {
      -ms-grid-column: 3;
      -ms-grid-row: 20;
    }

    &:nth-of-type(41) {
      -ms-grid-column: 1;
      -ms-grid-row: 21;
    }

    &:nth-of-type(42) {
      -ms-grid-column: 3;
      -ms-grid-row: 21;
    }

    &:nth-of-type(43) {
      -ms-grid-column: 1;
      -ms-grid-row: 22;
    }

    &:nth-of-type(44) {
      -ms-grid-column: 3;
      -ms-grid-row: 22;
    }

    &:nth-of-type(45) {
      -ms-grid-column: 1;
      -ms-grid-row: 23;
    }

    &:nth-of-type(46) {
      -ms-grid-column: 3;
      -ms-grid-row: 23;
    }

    &:nth-of-type(47) {
      -ms-grid-column: 1;
      -ms-grid-row: 24;
    }

    &:nth-of-type(48) {
      -ms-grid-column: 3;
      -ms-grid-row: 24;
    }

    &:nth-of-type(49) {
      -ms-grid-column: 1;
      -ms-grid-row: 25;
    }

    &:nth-of-type(50) {
      -ms-grid-column: 3;
      -ms-grid-row: 25;
    }
  }
}

@include media-breakpoint-up(lg) {
  .products-component {
    &__items {
      grid-template-columns: 3fr 3fr 3fr 3fr;
      grid-column-gap: 4rem;

      -ms-grid-columns: 3fr 4rem 3fr 4rem 3fr 4rem 3fr;
    }
  }

  .product {
    margin-bottom: 0;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 7;
      -ms-grid-row: 1;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }

    &:nth-of-type(6) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }

    &:nth-of-type(7) {
      -ms-grid-column: 5;
      -ms-grid-row: 2;
    }

    &:nth-of-type(8) {
      -ms-grid-column: 7;
      -ms-grid-row: 2;
    }

    &:nth-of-type(9) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-of-type(10) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-of-type(11) {
      -ms-grid-column: 5;
      -ms-grid-row: 3;
    }

    &:nth-of-type(12) {
      -ms-grid-column: 7;
      -ms-grid-row: 3;
    }

    &:nth-of-type(13) {
      -ms-grid-column: 1;
      -ms-grid-row: 4;
    }

    &:nth-of-type(14) {
      -ms-grid-column: 3;
      -ms-grid-row: 4;
    }

    &:nth-of-type(15) {
      -ms-grid-column: 5;
      -ms-grid-row: 4;
    }

    &:nth-of-type(16) {
      -ms-grid-column: 7;
      -ms-grid-row: 4;
    }

    &:nth-of-type(17) {
      -ms-grid-column: 1;
      -ms-grid-row: 5;
    }

    &:nth-of-type(18) {
      -ms-grid-column: 3;
      -ms-grid-row: 5;
    }

    &:nth-of-type(19) {
      -ms-grid-column: 5;
      -ms-grid-row: 5;
    }

    &:nth-of-type(20) {
      -ms-grid-column: 7;
      -ms-grid-row: 5;
    }

    &:nth-of-type(21) {
      -ms-grid-column: 1;
      -ms-grid-row: 6;
    }

    &:nth-of-type(22) {
      -ms-grid-column: 3;
      -ms-grid-row: 6;
    }

    &:nth-of-type(23) {
      -ms-grid-column: 5;
      -ms-grid-row: 6;
    }

    &:nth-of-type(24) {
      -ms-grid-column: 7;
      -ms-grid-row: 6;
    }

    &:nth-of-type(25) {
      -ms-grid-column: 1;
      -ms-grid-row: 7;
    }

    &:nth-of-type(26) {
      -ms-grid-column: 3;
      -ms-grid-row: 7;
    }

    &:nth-of-type(27) {
      -ms-grid-column: 5;
      -ms-grid-row: 7;
    }

    &:nth-of-type(28) {
      -ms-grid-column: 7;
      -ms-grid-row: 7;
    }

    &:nth-of-type(29) {
      -ms-grid-column: 1;
      -ms-grid-row: 8;
    }

    &:nth-of-type(30) {
      -ms-grid-column: 3;
      -ms-grid-row: 8;
    }

    &:nth-of-type(31) {
      -ms-grid-column: 5;
      -ms-grid-row: 8;
    }

    &:nth-of-type(32) {
      -ms-grid-column: 7;
      -ms-grid-row: 8;
    }

    &:nth-of-type(33) {
      -ms-grid-column: 1;
      -ms-grid-row: 9;
    }

    &:nth-of-type(34) {
      -ms-grid-column: 3;
      -ms-grid-row: 9;
    }

    &:nth-of-type(35) {
      -ms-grid-column: 5;
      -ms-grid-row: 9;
    }

    &:nth-of-type(36) {
      -ms-grid-column: 7;
      -ms-grid-row: 9;
    }

    &:nth-of-type(37) {
      -ms-grid-column: 1;
      -ms-grid-row: 10;
    }

    &:nth-of-type(38) {
      -ms-grid-column: 3;
      -ms-grid-row: 10;
    }

    &:nth-of-type(39) {
      -ms-grid-column: 5;
      -ms-grid-row: 10;
    }

    &:nth-of-type(40) {
      -ms-grid-column: 7;
      -ms-grid-row: 10;
    }

    &:nth-of-type(41) {
      -ms-grid-column: 1;
      -ms-grid-row: 11;
    }

    &:nth-of-type(42) {
      -ms-grid-column: 3;
      -ms-grid-row: 11;
    }

    &:nth-of-type(43) {
      -ms-grid-column: 5;
      -ms-grid-row: 11;
    }

    &:nth-of-type(44) {
      -ms-grid-column: 7;
      -ms-grid-row: 11;
    }

    &:nth-of-type(45) {
      -ms-grid-column: 1;
      -ms-grid-row: 12;
    }

    &:nth-of-type(46) {
      -ms-grid-column: 3;
      -ms-grid-row: 12;
    }

    &:nth-of-type(47) {
      -ms-grid-column: 5;
      -ms-grid-row: 12;
    }

    &:nth-of-type(48) {
      -ms-grid-column: 7;
      -ms-grid-row: 12;
    }

    &:nth-of-type(49) {
      -ms-grid-column: 1;
      -ms-grid-row: 13;
    }

    &:nth-of-type(50) {
      -ms-grid-column: 3;
      -ms-grid-row: 13;
    }

    &:nth-of-type(51) {
      -ms-grid-column: 5;
      -ms-grid-row: 13;
    }

    &:nth-of-type(52) {
      -ms-grid-column: 7;
      -ms-grid-row: 13;
    }
  }
}

@include media-breakpoint-up(xl) {
  .product {
    &__image {
      &:before {
        left: calc(50% - 22px);
        top: calc(50% - 22px);
        width: 44px;
        height: 44px;
        border: 4px solid;
        border-radius: 22px;
        font-size: 11px;
        line-height: 36px;
      }
    }
  }
}