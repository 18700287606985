.nav-container {
  display:grid;
  grid-template-columns: 0fr 12fr 0fr;
  grid-template-areas:
          "left-gutter navbar right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 0fr 12fr 0fr;

  background-color: $white;
  width: 100%;
  z-index: 9;
  transition: 0.3s;
  border-bottom: 3px solid $black;

  .navbar {
    grid-column: navbar;
    -ms-grid-column: 2;
    display:grid;

    grid-template-areas:
            "navleft brandlogo navright searchbox";
    justify-content: center;
    align-items: center;

    display: -ms-grid;
    -ms-grid-columns: 5fr auto 5fr;
    position: relative;
    z-index: 1;

    .navbar-brand {
      display: inline-block;
      font-size: 0;
      line-height: inherit;
      z-index: 1;
      grid-column: brandlogo;
      -ms-grid-column: 2;
      height: 0;

      img {
        max-width: 54px;
        transform: translateY(-10px);
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (16px/1.25), 16px, 1, 1);
      color: $body-color;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 96px;
      text-decoration: none;
      transition: 0.3s;
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        display: none;
      }
    }

    //mega submenu
    > .submenu {
      background-color: rgba($white,.95);
      position: absolute;
      top: 100%;
      left: -1rem;
      text-align: left;
      border-bottom: 3px solid $black;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin-left: 1rem;
          min-width: 200px;

          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            padding: 0.75rem 0;
            break-inside: avoid;

            a {
              position: relative;
              color: $body-color;
              text-decoration: none;
              transition: 0.3s;
            }

            &.active, &:hover {
              a {
                color: $primary;
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        color: $primary;
      }
    }

    //open submenu
    &:hover {
      > a:not(.btn) {
        color: $primary;
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
#nav-main {
  grid-column: navleft;

  -ms-grid-column: 1;
  text-align: right;
}
#nav-secundary {
  grid-column: navright;

  -ms-grid-column: 3;

  > li {
    display: inline-block;
    > a {
      display: inline-block;
      font-weight: 600;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      -ms-grid-columns: 5fr auto 3fr 2fr;
      .navbar-brand {
        img {
          max-width: 74px;
          transform: translateY(-44px);
        }
      }
    }
  }

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        //padding: 0 2rem;
        //line-height: 64px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    .nav-container {
      &:not(.sticky) {
        //position: absolute;
        //background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    //position: fixed;
    //top: 0;
    //left: 0;

    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0.3125rem 1.5rem;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  ul#nav-secundary {
    padding-left: 1.5rem;
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .nav-container {

  }
}

@include media-breakpoint-up(xl) {
  .nav-container {
    .navbar {
      height: 38px;
      //justify-content: space-between;
      .navbar-brand {
        img {
          max-width: 154px;
          transform: translateY(-36px);
        }
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .nav-container {
    //grid-template-columns: 1fr 12fr 1fr;
    //-ms-grid-columns: 1fr 12fr 1fr;
    .navbar {
      //justify-content: center;
      //grid-template-columns: 5fr auto 5fr;
    }
  }
  ul.nav-desktop {
    > li {
      //padding: 0.3125rem 0.75rem;
    }
  }
  //ul.nav-desktop {
  //  > li {
  //    padding: 0.3125rem 1.5rem;
  //  }
  //}
}