.form-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter form-component__title right-gutter"
          "left-gutter form-component__items right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &__title {
    padding-bottom: 1rem;
    @extend %abuget;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    font-weight: normal;
    grid-area: form-component__title;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }

  &__items {
    grid-area: form-component__items;
    -ms-grid-column: 2;
    -ms-grid-row: 2;

    > div {
      margin-bottom: 2rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .form-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 4rem 0;

    &__items {
      display:grid;
      grid-template-columns: 6fr 6fr;
      grid-column-gap: 2rem;

      display: -ms-grid;
      -ms-grid-columns: 6fr 2rem 6fr;

      > div {
        margin-bottom: 0;

        &:nth-of-type(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-of-type(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  .form-component {
    &__items {
      grid-column-gap: 4rem;

      -ms-grid-columns: 6fr 4rem 6fr;
    }
  }
}

@include media-breakpoint-up(xl) {
  .form-component {
    &__items {
      grid-column-gap: 10rem;

      -ms-grid-columns: 6fr 10rem 6fr;
    }
  }
}