.btn {
  display: inline-block;
  padding: 1rem 3rem 1rem 2rem;
  margin-bottom: 1rem;
  @include fluid-type($min_width, $max_width, (18px/1.4), 18px, (1.1/1.2), 1.1);
  color: $white;
  //font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 31px;
  border: none;
  cursor: pointer;

  &--arrow {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      @extend .gelrekoffie-icons;
      @extend .gelrekoffie-icons-arrow-next;
      font-size: 8px;
      transform: translateY(-50%);
    }
  }

  &--image {
    position: relative;

    &:after {
      content: "";
      background-image: url("../images/btn-image.png");
      position: absolute;
      top: 50%;
      right: calc(100% - 1.5rem);
      transform: translateY(-50%);
      width: 135px;
      height: 65px;
    }
  }

  &-primary {
    background-color: $red;

    &:hover {
      background-color: darken($red,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-link {
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    border-radius: 0;
    color: $red;

    &.btn--arrow {
      &:after {
        border-left: 2px solid $red;
        border-bottom: 2px solid $red;
      }
    }

    &:hover {
      //text-decoration: none;
    }
  }
}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}